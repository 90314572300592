function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import flatpickr from 'flatpickr';
var initialDate = new Date();
initialDate.setHours(0, 0, 0, 0);
var configDate = {
  enableTime: false,
  dateFormat: 'F j, Y',
  monthSelectorType: 'static',
  minDate: initialDate.getTime(),
  defaultDate: 'today'
};
var configTime = {
  enableTime: true,
  noCalendar: true,
  dateFormat: "H:i",
  minuteIncrement: 1,
  defaultDate: "12:00"
};

_toConsumableArray(document.querySelectorAll('.u-datePicker')).forEach(function (target) {
  return flatpickr(target, configDate);
});

_toConsumableArray(document.querySelectorAll('.u-timePicker')).forEach(function (target) {
  return flatpickr(target, configTime);
});

jQuery('body').on('click', '.wpcf7-field-group-add, .wpcf7-field-group-remove', function () {
  setTimeout(function () {
    _toConsumableArray(document.querySelectorAll('.u-datePicker')).forEach(function (target) {
      return flatpickr(target, {
        enableTime: false,
        dateFormat: 'F j, Y',
        monthSelectorType: 'static',
        minDate: initialDate.getTime()
      });
    });

    _toConsumableArray(document.querySelectorAll('.u-timePicker')).forEach(function (target) {
      return flatpickr(target, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        minuteIncrement: 1
      });
    });
  }, 400);
});