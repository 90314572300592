import 'popper.js';
import 'bootstrap';
import 'slick-carousel/slick/slick.min';
import 'intersection-observer';
import '@module/select2'; //import '@fancyapps/fancybox';
// import { jQueryScrollTo } from '@module/utils';

import '@module/iobserver';
import '@module/input-datetime';
import '@module/raq-forms'; //import '../../partials/components/form-modal/form-modal'

import '../sass/main.scss';