import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.string.replace-all.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
var $ = jQuery;
var url = 'https://api.chapmanfreeborn.aero/v1/locations?keyword=';
var changeInterval = null;
$('.wpcf7-form, .shortForms').on('keyup', '.searchLocation', function () {
  var _this = this;

  var ul = $(this).parent().parent().parent().parent().children('ul')[0];

  if ($(this).val() !== '') {
    clearInterval(changeInterval);
    changeInterval = setInterval(function () {
      fetch(url + $(_this).val()).then(function (resp) {
        return resp.json();
      }).then(function (data) {
        clearInterval(changeInterval);
        var airports = (data.airports || []).map(function (airport) {
          return "<li>".concat(airport.name, "</li>");
        });
        var cities = (data.cities || []).map(function (city) {
          return "<li>".concat(city.name, "</li>");
        });

        if (airports.length || cities.length) {
          ul.innerHTML = (airports.length ? '<span class="title">Airports</span>' + airports.toString().replaceAll(',', '') : '') + (cities.length ? '<span class="title">Cities</span>' + cities.toString().replaceAll(',', '') : '');
        } else {
          ul.innerHTML = "<span class=\"notice\">No results</span>";
        }
      }).catch(function (error) {
        console.log(error);
      });
    }, 1000);
  } else {
    ul.innerHTML = "<span class=\"notice\">Type to search locations.</span>";
  }
});
$('.wpcf7-form, .shortForms').on('focus', '.searchLocation', function () {
  var ul = $(this).parent().parent().parent().parent().children('ul');
  $(this).val('');
  ul.addClass('show');
  ul[0].innerHTML = "<span class=\"notice\">Type to search locations.</span>";
}).on('blur', '.searchLocation', function () {
  var ul = $(this).parent().parent().parent().parent().children('ul');
  ul.removeClass('show');
});
$('.wpcf7-form, .shortForms').on('click', '.routeContainer > div > ul > li', function () {
  var input = $(this).parent().parent().find('.searchLocation');
  $(this).parent().removeClass('show');
  input.val($(this).text());
});
$('.wpcf7-form').on('click', '.measurementSelection .wpcf7-list-item', function () {
  var lengthEl = $('span.unit.length');
  var weightEl = $('span.unit.weight');

  if ($(this).hasClass('first')) {
    weightEl.text('kg');
    lengthEl.each(function (index) {
      $(this).text('cm');
    });
  } else {
    weightEl.text('lb');
    lengthEl.each(function (index) {
      $(this).text('in');
    });
  }
});
$('body').on('click', '.wpcf7-field-group-add, .wpcf7-field-group-remove', function () {
  setTimeout(function () {
    var lengthEl = $('span.unit.length');
    var weightEl = $('span.unit.weight');

    if ($('.measurementSelection .wpcf7-list-item.first input').is(':checked')) {
      weightEl.text('kg');
      lengthEl.each(function (index) {
        $(this).text('cm');
      });
    } else {
      weightEl.text('lb');
      lengthEl.each(function (index) {
        $(this).text('in');
      });
    }
  }, 400);
});
$('.copyValues').on('click', function () {
  var activeTab = $('.shortForms .wpb_tab.visible-tab').index();
  var modalActiveTab = $('.popmake .wpb_tabs_nav li:nth-child(' + activeTab + ') a');
  var departure = $('.shortForms .visible-tab .departure .searchLocation').val();
  var destination = $('.shortForms .visible-tab .destination .searchLocation').val();
  var date = $('.shortForms .visible-tab .date input').val();
  var time = $('.shortForms .visible-tab .time input').val();
  modalActiveTab.trigger('click');
  setTimeout(function () {
    var modalDeparture = $('.popmake .visible-tab .departure .searchLocation');
    var modalDestination = $('.popmake .visible-tab .destination .searchLocation');
    var modalDate = $('.popmake .visible-tab .date input');
    var modalTime = $('.popmake .visible-tab .time input');
    modalDeparture.val(departure);
    modalDestination.val(destination);
    modalDate.val(date);
    modalTime.val(time);
  }, 100);
});